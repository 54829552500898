import styled from '@emotion/styled'
import {device} from '../../devices'

export const ButtonGradient = styled.button`
  width: 22.7vw;
  height: 4.49vw;
  font-size: 1.5vw;
  letter-spacing: -0.002vw;
  color: ${({theme}) => theme.white};
  border-radius: 0.3vw;
  box-shadow: 0.19vw 0.19vw 1.367vw 0 rgba(0, 0, 0, 0.26);
  background-image: linear-gradient(91deg, #0000ff -11%, #0ccdfe 68%, #66ffe5 110%);
  border: none;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  
  @media ${device.mobileL} {
    padding: 10px 20px;
    width: auto;
    height: auto;
    font-size: 130% !important;
  }
`

export const GradientButtonLight = styled.button`
  background: linear-gradient(268deg, #0accff 21%, #66ffe5 130%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 22.7vw;
  height: 4.49vw;
  font-size: 1.5vw;
  letter-spacing: -0.002vw;
  object-fit: contain;
  border-radius: 0.3vw;
  box-shadow: 0.19vw 0.19vw 1.367vw 0 rgba(0, 0, 0, 0.18);
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  
  p {
    width: auto;
    background: linear-gradient(268deg, #0accff 21%, #66ffe5 130%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @media ${device.mobileL} {
    padding: 10px 20px;
    width: auto;
    height: auto;
    font-size: 80% !important;
    p {
      font-size: 180% !important;
    }  
  }
`
