import styled from '@emotion/styled'
import {device} from '../devices'

export const TitleGradientHome = styled.h2`
  width: fit-content;
  height: auto;
  font-size: 3.5vw;
  min-height: 3.9vw;
  background: ${({color}) => color ? '' : 'linear-gradient(91deg, #0000ff -1%, #0ccdfe 51%, #66ffe5 128%)' };
  color: ${({color}) => color ? color : ''};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({color}) => color ? '' : 'transparent' };
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  
  @media ${device.mobileL} {
    min-height: auto;
    font-size: 225%;
    text-align: center;
    width: 100%;
    line-height: normal;
  }
`
