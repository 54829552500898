import styled from '@emotion/styled'
import {device} from '../devices'

export const Text = styled.p`  
  font-size: 1.4vw;
  letter-spacing: 0.015vw;
  line-height: normal;
  font-weight: 500;
  b {
    font-weight: bold;
  }
  
  @media ${device.mobileL} {
    font-size: 120% !important;
  }
`
